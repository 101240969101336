import React from 'react';
import Styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

export const Footer = () => {
  return (
    <div>
      <div className={Styles.copyright}>
        <p>
          Copyright{' '}
          <i>
            <FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon>
          </i>{' '}
          Dapp Architects 2024
        </p>
      </div>
    </div>
  );
};
