import React, { useState } from 'react';
import Styles from './Navbar.module.css';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faHouse,
  faAddressCard,
  faDiagramProject,
  faHistory,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div id="navbar" className={Styles.NavbarTop}>
      {/* MENU BELOW */}
      <div className={Styles.menuRow}>
        <div className={Styles.menuCol1}></div>
        <div className={Styles.menuCol2}>
          <div className={Styles.menuContainer}>
            <nav>
              <ul
                id="sidemenu"
                className={Styles.sideMenu}
                style={{ right: isOpen ? '0' : '-160px' }}
              >
                {/* Close Menu */}
                <li className={Styles.listItem}>
                  <i className={Styles.smallScreenOnly}>
                    <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
                  </i>

                  <Link
                    to={'/'}
                    className={`${Styles.link} ${
                      location.pathname === '/' ? Styles.active : ''
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li className={Styles.listItem}>
                  <i className={Styles.smallScreenOnly}>
                    <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>
                  </i>

                  <Link
                    to={'/about'}
                    className={`${Styles.link} ${
                      location.pathname === '/about' ? Styles.active : ''
                    }`}
                  >
                    About
                  </Link>
                </li>
                <li className={Styles.listItem}>
                  <i className={Styles.smallScreenOnly}>
                    <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon>
                  </i>

                  <Link
                    to={'/experience'}
                    className={`${Styles.link} ${
                      location.pathname === '/experience' ? Styles.active : ''
                    }`}
                  >
                    Experience
                  </Link>
                </li>
                <li className={Styles.listItem}>
                  <i className={Styles.smallScreenOnly}>
                    <FontAwesomeIcon icon={faDiagramProject}></FontAwesomeIcon>
                  </i>

                  <Link
                    to={'/projects'}
                    className={`${Styles.link} ${
                      location.pathname === '/projects' ? Styles.active : ''
                    }`}
                  >
                    Projects
                  </Link>
                </li>
                <li className={Styles.listItem}>
                  <i className={Styles.smallScreenOnly}>
                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                  </i>

                  <Link
                    to={'/contact'}
                    className={`${Styles.link} ${
                      location.pathname === '/contact' ? Styles.active : ''
                    }`}
                  >
                    Contact
                  </Link>
                </li>
                <i
                  onClick={closeMenu}
                  className={`${Styles.fAIconClose} ${Styles.closeIcon}`}
                >
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </i>
              </ul>
              {/* Open Menu */}
              <i onClick={openMenu} className={Styles.fAIconOpen}>
                <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
              </i>
            </nav>
            {/* MENU ABOVE */}
          </div>
        </div>
      </div>
    </div>
  );
};
