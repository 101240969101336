import React, { useState } from 'react';
import './About.css';
import Styles from '../About/Icons.module.css';
import SkillImage from '../Helper-Components/SkillImage';
import skills from '../data/skills.json';

export const About = () => {
  const [toggleState, setToggleState] = useState('skills');

  function openTab(tabName) {
    setToggleState(tabName);
  }

  return (
    <div id="about">
      <div className="outContainer">
        <div className="container">
          <div className="about">
            <div className="row">
              <div className="aboutCol1">
                <img src="../assets/hero/image1.jpg" alt="Me"></img>
              </div>
              <div className="aboutCol2">
                <h1 className="subTitle">About Me</h1>
                <p>
                  As a seasoned Senior Software Engineer and adept
                  Project/Program Manager with over two decades of experience, I
                  am dedicated to delivering client-centric solutions that
                  exceed expectations. My track record includes designing
                  innovative applications and effectively managing projects to
                  achieve high client satisfaction. I am committed to staying at
                  the forefront of technology by continually honing my technical
                  skills and keeping abreast of industry trends. <br />
                  <br />
                  My expertise spans a wide range of areas, including
                  requirement gathering and analysis, architectural design,
                  component development, and interface design for client-server,
                  web-based, workflow, and blockchain applications. I excel in
                  object-oriented analysis and design, ensuring that solutions
                  are not only functional but also scalable and maintainable.{' '}
                  <br />
                  <br />I bring a wealth of experience in database design,
                  end-user training, documentation, and support, ensuring that
                  projects are well-rounded and successful from inception to
                  completion. My comprehensive understanding of the Software
                  Development Lifecycle (SDLC) allows me to navigate complex
                  projects with ease, delivering high-quality results on time
                  and within budget.
                </p>
                <div className="tabTitles">
                  <p
                    className={
                      toggleState === 'skills'
                        ? 'tabLinks activeLink'
                        : 'tabLinks'
                    }
                    onClick={() => openTab('skills')}
                  >
                    Skills
                  </p>
                  <p
                    className={
                      toggleState === 'experience'
                        ? 'tabLinks activeLink'
                        : 'tabLinks'
                    }
                    onClick={() => openTab('experience')}
                  >
                    Experience
                  </p>
                  <p
                    className={
                      toggleState === 'education'
                        ? 'tabLinks activeLink'
                        : 'tabLinks'
                    }
                    onClick={() => openTab('education')}
                  >
                    Education
                  </p>
                  <p
                    className={
                      toggleState === 'training'
                        ? 'tabLinks activeLink'
                        : 'tabLinks'
                    }
                    onClick={() => openTab('training')}
                  >
                    Training & Certifications
                  </p>
                </div>

                <div
                  className={
                    toggleState === 'skills'
                      ? 'tabContents activeTab'
                      : 'tabContents'
                  }
                  id="skills"
                >
                  <ul>
                    <li>
                      <span>Blockchain Technologies</span>
                      <br />
                      Ethereum | Hyperledger | Solidity | Golang (working
                      knowledge) | Rust (working knowledge) | Hardhat | Ganache
                      | Brownie | Truffle | OpenZeppelin | Cryptography | ERC-20
                      | ERC-1400 | Infura | Alchemy
                    </li>
                    <li>
                      <span>DApp Development</span>
                      <br />
                      Web3 | EthersJS | JavaScript | TypeScript | NodeJS |
                      ReactJS | NextJS | NodeJS | Redux
                    </li>
                    <li>
                      <span>Web App Development</span>
                      <br />
                      HTML | CSS | Bootstrap | AJAX | Material UI | Figma |
                      React Icons | ImageKit | SpriteCow | JSON | RESTful API
                    </li>
                    <li>
                      <span>Other Languages</span>
                      <br />
                      Python | C# | Visual Basic | Lotus Notes
                    </li>
                    <li>
                      <span>Databases</span>
                      <br />
                      SQL Server | MySQL | Oracle | MariaDB
                    </li>
                    <li>
                      <span>Other Technologies</span>
                      <br />
                      Anaconda | Jupiter Notebook | Pandas | Dot Net
                    </li>
                    <li>
                      <span>Operating Systems</span>
                      <br />
                      MacOS | Windows | Linux
                    </li>
                    <li>
                      <span>Other Skills</span>
                      <br />
                      Project Management | Technical Documentation
                    </li>
                  </ul>
                </div>

                <div
                  className={
                    toggleState === 'experience'
                      ? 'tabContents activeTab'
                      : 'tabContents'
                  }
                  id="experience"
                >
                  <ul>
                    <li>
                      <span>Smart Contract Development</span>
                      <br />
                      Ethereum Solidity Based Smart Contracts
                    </li>
                    <li>
                      <span>DApp Development</span>
                      <br />
                      Blockchain Based Distributed Apps
                    </li>
                    <li>
                      <span>Web App Development</span>
                      <br />
                      Responsive E-Commerce Websites
                    </li>
                    <li>
                      <span>UI/UX Development</span>
                      <br />
                      Designing Web/App Landing Pages/interfaces
                    </li>
                  </ul>
                </div>

                <div
                  className={
                    toggleState === 'education'
                      ? 'tabContents activeTab'
                      : 'tabContents'
                  }
                  id="education"
                >
                  <ul>
                    <li>
                      <span>Cleveland State University</span>
                      <br />
                      Graduate Coursework in MS Computer Information Systems
                    </li>
                    <li>
                      <span>King Fahd University of Petroleum & Minerals</span>
                      <br />
                      Graduate Coursework in Master of Business Administration
                    </li>
                    <li>
                      <span>NED University of Engineering and Technology</span>
                      <br />
                      BS in Mechanical Engineering
                    </li>
                  </ul>
                </div>

                <div
                  className={
                    toggleState === 'training'
                      ? 'tabContents activeTab'
                      : 'tabContents'
                  }
                  id="training"
                >
                  <ul>
                    <li>
                      <span>Blockchain Council</span>
                      <br />
                      Certified Blockchain Architect
                    </li>
                    <li>
                      <span>Blockchain Council</span>
                      <br />
                      Certified Ethereum Developer
                    </li>
                    <li>
                      <span>Brainnest</span>
                      <br />
                      Blockchain Developer Internship
                    </li>
                    <li>
                      <span>Dapp University</span>
                      <br />
                      Blockchain Bootcamp
                    </li>
                    <li>
                      <span>IBM</span>
                      <br />
                      Python for Data Science
                    </li>
                    <li>
                      <span>Intensive Cloud Computing</span>
                      <br />
                      AWS, Azure, Google Cloud, Oracle Cloud Certificate
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* New Code */}
          <div className={Styles.skills}>
            {skills.map((skill, id) => {
              return (
                <div key={id} className={Styles.skill}>
                  <a href={skill.url} target="_blank" rel="noreferrer">
                    <div className={Styles.skillImageContainer}>
                      <SkillImage imageSrc={skill.imageSrc} alt={skill.title} />
                    </div>
                  </a>
                  <p>{skill.title}</p>
                </div>
              );
            })}
          </div>
          {/* New Code */}
        </div>
      </div>
    </div>
  );
};
