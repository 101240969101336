import React from 'react';
import Styles from './Projects.module.css';
import projects from '../data/projects.json';
import { ProjectCard } from './ProjectCard';

export const Projects = () => {
  return (
    <div id="projects">
      <div className={Styles.outContainer}>
        <div className={Styles.container}>
          <h1 className={Styles.subTitle}>My Projects</h1>
          {/* <div className={Styles.projectList}>
              <div className={Styles.project}>
                <img src="./assets/projects/work-1.png" />
                <div className={Styles.layer}>
                  <h3>Social Media App</h3>
                  <p>
                    The app connects you to the talented people around the
                    world. Download it from the App Store.
                  </p>
                  <a href="#">
                    <i>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className={Styles.fAIcon}
                      ></FontAwesomeIcon>
                    </i>
                  </a>
                </div>
              </div>
              <div className={Styles.project}>
                <img src="./assets/projects/work-2.png" />
                <div className={Styles.layer}>
                  <h3>Music App</h3>
                  <p>
                    The app connects you to the talented people around the
                    world. Download it from the App Store.
                  </p>
                  <a href="#">
                    <i>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className={Styles.fAIcon}
                      ></FontAwesomeIcon>
                    </i>
                  </a>
                </div>
              </div>
              <div className={Styles.project}>
                <img src="./assets/projects/work-3.png" />
                <div className={Styles.layer}>
                  <h3>Online Shopping App</h3>
                  <p>
                    The app connects you to the talented people around the
                    world. Download it from the App Store.
                  </p>
                  <a href="#">
                    <i>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className={Styles.fAIcon}
                      ></FontAwesomeIcon>
                    </i>
                  </a>
                </div>
              </div>
            </div> */}
          {/* Added */}
          <div className={Styles.projects}>
            {projects.map((project, id) => {
              return <ProjectCard key={id} project={project} />;
            })}
          </div>
          {/* Added */}
          {/* <a href="#" className={Styles.btn}>
            See More...
          </a> */}
        </div>
      </div>
    </div>
  );
};
