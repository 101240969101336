import React from 'react';
import Styles from './Home.module.css';

export const Home = () => {
  const logo = '../assets/logo/dapp-architects-website-logo.png';

  return (
    <div id="home">
      <div className={Styles.container}>
        <div className={Styles.row}>
          <div className={Styles.navCol1}></div>
          <div className={Styles.navCol2}>
            <nav className={Styles.nav}>
              <img src={logo} className={Styles.logo} alt="Logo" />
            </nav>
          </div>
        </div>
        <div className={Styles.homeText}>
          <h1>
            Hi, my name is <span>Faruk</span> Ansari <br />
          </h1>
          <p>
            I'm a Blockchain Smart Contract/DApp <br />
            and Full Stack Engineer
          </p>
        </div>
      </div>
    </div>
  );
};
